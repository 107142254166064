import React, { useContext, useEffect, useState } from 'react';
import { DNAContext } from '../context/DNAContext';
import { useNavigate } from 'react-router-dom';
import { buildSvg, setStyle } from '../contract/contractMethods/svgMethods';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import chosen from '../assets/images/chosen.png';
import styles from '../styles.json';
import upIcon from '../assets/images/up-icon.png';
import { Stepper } from 'react-form-stepper';
import StepperContainer from '../components/StepperContainer';
import { StepperContext } from '../context/StepperContext';

const Colorpicker = () => {
  const [colors, setColors] = useState(null);
  const dnaCtx = useContext(DNAContext);
  const [currentSvg, setcurrentSvg] = useState(null);

  const currentDna = dnaCtx.dna;
  const [dna, setDna] = useState(currentDna);
  const nrOfDivisions = dnaCtx.nrOfDivisions;
  console.log(nrOfDivisions, 'nr of divisions');
  const shape = dnaCtx.selectedShape;
  const { setCurrentStep } = useContext(StepperContext);

  const [newDna, setNewDna] = useState(null);

  const [recoloredSvg, setRecoloredSvg] = useState(null);

  const { t } = useTranslation();

  const [showmoreColors, setShowmoreColors] = useState(false);
  const [arrowId, setArrowId] = useState(null);

  const [color1, setColor1] = useState(null);
  const [color2, setColor2] = useState(null);
  const [color3, setColor3] = useState(null);
  const [color4, setColor4] = useState(null);
  const [color5, setColor5] = useState(null);

  //console.log(currentDna, 'current dna');

  const [colorsSelected, setColorsSelected] = useState(
    new Array(nrOfDivisions).fill(false)
  );
  console.log(colorsSelected + ' init colors selected');

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const svg = await buildSvg(dna);
      setcurrentSvg(svg);
      dnaCtx.setGeneratedSvg(svg);
    };
    fetch();

    const fetchColors = async () => {
      const filteredStyles = Object.values(styles).filter(
        (v) => v.type === 'fill' && v.category === '1'
      );
      setColors(filteredStyles);
    };

    fetchColors();
  }, []);

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  const handleColorClick = (e) => {
    const colorId = parseInt(e.target.id);

    //console.log(e.target.style.background, "styleeeeeeeeeeee");

    const node = e.target;
    const parentNode = node.parentNode;
    const grandparentNode = parentNode.parentNode;
    const subclassId = parseInt(grandparentNode.id);

    const rgb = e.target.style.background;
    const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const matches = rgb.match(regex);
    const numbers = matches.slice(1).map(Number);
    const hex = rgbToHex(numbers[0], numbers[1], numbers[2]);

    if (subclassId === 1) {
      setColor1(hex.toUpperCase());
    } else if (subclassId === 2) {
      setColor2(hex.toUpperCase());
    } else if (subclassId === 3) {
      setColor3(hex.toUpperCase());
    } else if (subclassId === 4) {
      setColor4(hex.toUpperCase());
    } else if (subclassId === 5) {
      setColor5(hex.toUpperCase());
    }

    const classId = parseInt(shape.classId);
    const newColorsSelected = [...colorsSelected];
    newColorsSelected[subclassId - 1] = true;
    setColorsSelected(newColorsSelected);

    let newDnaWithStyle;
    if (newDna === null) {
      newDnaWithStyle = setStyle(classId, subclassId, colorId, dna);
    } else if (newDna !== null) {
      newDnaWithStyle = setStyle(classId, subclassId, colorId, newDna);
    }

    setNewDna(newDnaWithStyle);
  };

  // useEffect(() => {
  //   const recoloredSvgFromLocalstorage = localStorage.getItem('recolored-svg');
  //   if (recoloredSvgFromLocalstorage) {
  //     setRecoloredSvg(recoloredSvgFromLocalstorage);
  //   }
  // }, []);

  useEffect(() => {
    const fetchSvg = async (dna) => {
      const svg = await buildSvg(dna);
      return svg;
    };

    const updateRecoloredSvg = async () => {
      if (newDna) {
        const svg = await fetchSvg(newDna);
        dnaCtx.setDNA(newDna);
        setRecoloredSvg(svg);

        // localStorage.setItem('recolored-svg', svg);
        // localStorage.setItem('recolor-dna', newDna);
      } else {
        const svg = await fetchSvg(currentDna);
        dnaCtx.setDNA(currentDna);
        setRecoloredSvg(svg);
      }
    };
    updateRecoloredSvg();
  }, [newDna, dnaCtx]);

  const goToImageSelect = () => {
    navigate('/motivum');
  };

  const goBackToFormak = () => {
    navigate('/formak');
  };

  const handleShowChanges = (e) => {
    setShowmoreColors(!showmoreColors);

    const arrowId = e.target.id;
    console.log(arrowId, 'arrow id');
    setArrowId(arrowId);
  };

  //console.log(colors, 'colors')
  //console.log(color5, "color 5");

  return (
    <div className="colorpicker-outer">
      <div className="colorpicker">
        <div>
          <h2 className="page-title-pajzsok">{t('title')}</h2>
          <p className="screens-text">{t('colorText')}</p>
          <div className="colorpicker-content">
            {currentSvg && !recoloredSvg && (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentSvg,
                }}
                key={currentSvg}
                style={
                  {
                    // width: "305px",
                    // height: "305px",
                    // border: "1px solid #00000033",
                    // margin: "0 auto",
                    //filter: "grayscale(100%) brightness(120%) invert(75%)",
                  }
                }
                className="currentSvg"
              ></div>
            )}

            {recoloredSvg && (
              <div
                dangerouslySetInnerHTML={{
                  __html: recoloredSvg,
                }}
                style={
                  {
                    // width: "305px",
                    // height: "305px",
                    // border: "1px solid #00000033",
                    // margin: "0 auto",
                  }
                }
                className="currentSvg"
              ></div>
            )}

            {colors && (
              <>
                <div className="colors">
                  {[...Array(nrOfDivisions)].map((_, index) => {
                    // console.log(_, "nemtudommi");
                    // console.log(index, "index colorsba");
                    return (
                      <div
                        key={index}
                        className="outer-color-holder"
                        id={index + 1}
                      >
                        <div
                          className="color-holder-header"
                          id={index + 1}
                          onClick={handleShowChanges}
                          key={index}
                        >
                          <div className="color-text">
                            {color1 && index === 0 ? (
                              <div className="selected-color-div">
                                <div
                                  style={{
                                    background: color1,
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '30px',
                                  }}
                                ></div>
                                {colors.map((color) => {
                                  if (color.code === color1) {
                                    //console.log(color.name, "color name");
                                    return <p>{color.name}</p>;
                                  }
                                })}
                              </div>
                            ) : color2 && index === 1 ? (
                              <div className="selected-color-div">
                                <div
                                  style={{
                                    background: color2,
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '30px',
                                  }}
                                ></div>
                                {colors.map((color) => {
                                  if (color.code === color2) {
                                    //console.log(color.name, "color name");
                                    return <p>{color.name}</p>;
                                  }
                                })}
                              </div>
                            ) : color3 && index === 2 ? (
                              <div className="selected-color-div">
                                <div
                                  style={{
                                    background: color3,
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '30px',
                                  }}
                                ></div>
                                {colors.map((color) => {
                                  if (color.code === color3) {
                                    //console.log(color.name, "color name");
                                    return <p>{color.name}</p>;
                                  }
                                })}
                              </div>
                            ) : color4 && index === 3 ? (
                              <div className="selected-color-div">
                                <div
                                  style={{
                                    background: color4,
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '30px',
                                  }}
                                ></div>
                                {colors.map((color) => {
                                  if (color.code === color4) {
                                    //console.log(color.name, "color name");
                                    return <p>{color.name}</p>;
                                  }
                                })}
                              </div>
                            ) : color5 && index === 4 ? (
                              <div className="selected-color-div">
                                <div
                                  style={{
                                    background: color5,
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '30px',
                                  }}
                                ></div>
                                {colors.map((color) => {
                                  if (color.code === color5) {
                                    console.log(color.name, 'color name');
                                    return <p>{color.name}</p>;
                                  }
                                })}
                              </div>
                            ) : (
                              <>{index + 1}. {t("choseColor")}</>
                            )}
                          </div>
                          <img
                            src={upIcon}
                            className="up-icon"
                            style={{
                              transform: showmoreColors
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                              marginRight: !showmoreColors ? '21px' : '',
                              paddingRight: showmoreColors ? '21px' : '',
                            }}
                          ></img>
                        </div>
                        {!showmoreColors ? (
                          <></>
                        ) : (
                          <div className="color-holder" id={index + 1}>
                            {colors.map((color, index) => {
                              //console.log(color.code, 'colorcode')
                              const colorId = color.id;
                              return (
                                <div className="single-color" key={index}>
                                  <div
                                    style={{
                                      background: color.code,
                                      width: '24px',
                                      height: '24px',
                                      borderRadius: '30px',
                                      boxShadow:
                                        '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                    key={index}
                                    id={colorId}
                                    onClick={handleColorClick}
                                  ></div>
                                  <div className="color-name">{color.name}</div>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {/* Add any other content for the card here */}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="holder">
          <div className="langpage-footer" style={{ bottom: '0' }}>
            <Footer />
            {/* </div> */}

            <div className="sticky-button-holder">
              <div className="buttons-holder">
                <button
                  className="select-button"
                  onClick={() => {
                    goToImageSelect();
                    setCurrentStep(3);
                  }}
                  disabled={
                    !colorsSelected.every((selected) => selected === true)
                  }
                >
                    {t("choseButton")}
                </button>

                <button
                  className="back-button"
                  onClick={() => {
                    localStorage.clear();
                    dnaCtx.setDNA(new Uint8Array(66));
                    dnaCtx.setScannedAddress(null);
                    setCurrentStep(0);
                    navigate('/');
                  }}
                >
                     {t("backButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colorpicker;
