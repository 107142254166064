import { createContext, useState } from 'react';

export const StepperContext = createContext({
  currentStep: 0,
  setCurrentStep: () => {},
});

const StepperContextProvider = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  return (
    <StepperContext.Provider
      value={{
        currentStep: currentStep,
        setCurrentStep: setCurrentStep,
      }}
    >
      {props.children}
    </StepperContext.Provider>
  );
};

export default StepperContextProvider;
