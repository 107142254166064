import { useContext } from 'react';
import { Stepper } from 'react-form-stepper';
import { StepperContext } from '../context/StepperContext';

const StepperContainer = () => {
  const { currentStep } = useContext(StepperContext);

  
  return (
    <Stepper
      className="react-stepper"
      stepClassName="react-stepper-step"
      steps={[{}, {}, {}, {}]}
      activeStep={currentStep}
    />
  );
};

export default StepperContainer;
