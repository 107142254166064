import React, { useState, useEffect, useContext } from "react";
import QrScanner from "../components/QRScanner";
import { useNavigate } from "react-router-dom";
import { DNAContext } from "../context/DNAContext";

const Scanner = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const navigate = useNavigate();
  const sourceCtx = useContext(DNAContext);

  return (
    <>
    <QrScanner setWalletAddress={(address)=>{setWalletAddress(address)}}/>
    </>
  );
};

export default Scanner;
