import React, { useContext } from "react";
import config from "../config.json";
import { DNAContext } from "../context/DNAContext";

const Footer = () => {

const ctx = useContext(DNAContext);

  const ccLink = (
    <a href="https://www.camelcoding.com/" target="_blank" className="ccLink">
      CamelCoding
    </a>
  );

  return (
    <div className="footer" style={{ color: config.MUZEUM.textColor }}>
      <div className="ccHolder">
        <p className="poweredBy" style={{ color: config.MUZEUM.textColor }}>Powered by: {ctx.isKiosk ? ccLink : <span>CamelCoding</span>}</p>
        <p className="poweredBy" style={{ color: config.MUZEUM.textColor }}>In partnership with: <span>Cloudscale Digital</span></p>
      </div>
    </div>
  );
};

export default Footer;
