import React, { useContext, useEffect, useState } from "react";
import {
  getBalance,
  getTokenOfOwner,
  getTokenUri,
} from "../contract/contractMethods/contractMethods";
import { DNAContext } from "../context/DNAContext";
import { useNavigate } from "react-router-dom";
import { getTokenData } from "../contract/contractMethods";
import { useExperienceCards } from "../contract/contractMethods/crestRequest";

const Crest = () => {
  const ctx = useContext(DNAContext);
  const navigate = useNavigate();
  const [balanceOfUser, setBalanceOfUser] = useState(null);

  const [tokenUri, settokenUri] = useState(null);

  const { usersCards } = useExperienceCards(ctx.scannedAddress);
  console.log(usersCards, "user cards");

  const contract = "0x3867F9DE528579D5cd6717E779eabAfBDFb96C43";

  useEffect(() => {
    if (!ctx.scannedAddress) {
      navigate("/home");
    }

    document.getElementsByClassName("header")[0].style.display = "flex";

    const fetchData = async () => {
      await getBalance(ctx.scannedAddress)
        .then((response) => {
          console.log(response, "balance");
          setBalanceOfUser(response);
        })
        .catch((e) => {
          console.log(e, "error");
        });
    };
    fetchData();
    // console.log(ctx.scannedAddress);
    // const balance = fetchData();
    // if (balance === 0) {
    //   navigate("/mint");
    // }

    setTimeout(() => {
      navigate("/home");
    }, 10000);
  }, []);

  const fetchTokenData = async () => {
    const tokenData = await getTokenData(ctx.scannedAddress, contract).catch(
      (e) => {
        console.log(e, "error");
      }
    );
    console.log(tokenData, "token data");
    return tokenData;
  };

  function stringToDnaArray(myStr) {
    myStr = myStr.slice(2);
    let arr = new Uint8Array(66);
    let j = 0;
    for (let i = 0; i < myStr.length; i += 2) {
      let numStr = myStr[i] + myStr[i + 1];
      console.log(numStr, "num str");
      arr[j] = parseInt(numStr, 16);
      j += 1;
    }
    return arr;
  }

  useEffect(() => {
    (async () => {
      if (balanceOfUser === 0) {
        navigate("/mint");
      }
    })();
  }, [balanceOfUser]);

  return (
    <div>
      <div className="crest-div">
        {usersCards && (
          <>
            <div className="crest-texts">
              <p className="home-texts">
                Már létrehoztál egy családi címert, íme:{" "}
              </p>
            </div>
            <div
              className="crest-svg"
              style={{ height: "400px", width: "400px" }}
              dangerouslySetInnerHTML={{ __html: usersCards }}
            />
            <p className="home-texts">
              Címer módosítása és bővítése hamarosan...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Crest;
