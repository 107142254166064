import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationHu from "./assets/locales/hu/translation.json";
import translationEn from "./assets/locales/en/translation.json";
import translationRo from "./assets/locales/ro/translation.json";

const fallbackLng = ["hu"];
const availableLanguages = ["hu", "ro", "en"];

const resources = {
  en: {
    translation: translationEn,
  },
  hu: {
    translation: translationHu,
  },
  ro: {
    translation: translationRo,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
