import { createContext, useState } from "react";

export const DNAContext = createContext({
  dna: null,
  setDNA: () => {},
  language: "hu",
  setLanguage: () => {},
  selectedbackground: null,
  setSelectedBackground: () => {},
  generatedSvg: null,
  setGeneratedSvg: () => {},
  nrOfDivisions: null,
  setNrOfDivisions: () => {},
  selectedShape: null,
  setSelectedShape: () => {},
  scannedAddress: null,
  setScannedAddress: () => {},
  stepNr: 1,
  setStepNr: () => {},
  generatedQR: null,
  setGeneratedQR: () => {},
  nrOfSteps: 4,
  isKiosk: false,
  setIsKiosk: () => {},
});

const DNAContextProvider = (props) => {
  const [dna, setDna] = useState(new Uint8Array(66));
  const [background, setBackground] = useState(null);
  const [svg, setSvg] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const [shape, setShape] = useState(null);
  const [address, setScannedAddress] = useState("");
  const [step, setStep] = useState(1);
  const [qr, setQr] = useState(null);
  const [nrOfSteps, setNrOfSteps] = useState(4);
  const [kiosk, setKiosk] = useState(false);

  const [selectedLanguage, setselectedLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "hu"
  );

  const setDNS = (dns) => {
    setDna(dns);
  };

  const setLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setselectedLanguage(lang);
  };

  const setSelectedBackground = (background) => {
    setBackground(background);
  };

  const setBuiltSvg = (svg) => {
    setSvg(svg);
  };

  const setDivisionNr = (nr) => {
    setDivisions(nr);
  };

  const selectShape = (shape) => {
    setShape(shape);
  };

  const setAddress = (addr) => {
    setScannedAddress(addr);
  };

  const setNrOfStep = (step) => {
    setStep(step);
  };

  const generateQR = (qr) => {
    setQr(qr);
  };
  const setIsKiosk = () => {
    setKiosk(true);
  };

  return (
    <DNAContext.Provider
      value={{
        dna: dna,
        setDNA: setDna,
        language: selectedLanguage,
        setLanguage: setLanguage,
        selectedbackground: background,
        setSelectedBackground: setSelectedBackground,
        generatedSvg: svg,
        setGeneratedSvg: setBuiltSvg,
        nrOfDivisions: divisions,
        setNrOfDivisions: setDivisionNr,
        selectedShape: shape,
        setSelectedShape: selectShape,
        scannedAddress: address,
        setScannedAddress: setScannedAddress,
        stepNr: step,
        setStepNr: setNrOfStep,
        generatedQR: qr,
        setGeneratedQR: generateQR,
        nrOfSteps: nrOfSteps,
        isKiosk: kiosk,
        setIsKiosk: setKiosk,
      }}
    >
      {props.children}
    </DNAContext.Provider>
  );
};

export default DNAContextProvider;
