import React, { useState, useContext, useEffect } from "react";
import { DNAContext } from "../context/DNAContext";
import QRCode from "react-qr-code";
import exit from "../assets/images/x-circle.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QRViewer = () => {
  const dnaCtx = useContext(DNAContext);
  const [dna, setDna] = useState(dnaCtx.dna);
  const navigate = useNavigate();

  //console.log(dnaCtx.scannedAddress, "address");
  //console.log(dnaCtx.dna, 'dns')
  const { t } = useTranslation();
  function dnaArrayToString(dna) {
    let dnaString = "0x";
    dna.forEach((dnaElement) => {
      if (dnaElement < 16) {
        dnaString += "0";
      }
      const hex = dnaElement.toString(16);
      dnaString += hex;
    });
    return dnaString;
  }

  // function stringToDnaArray(myStr) {
  //   myStr = myStr.slice(2);
  //   let arr = new Uint8Array(66);
  //   let j = 0;
  //   for (let i = 0; i < myStr.length; i += 2) {
  //     let numStr = myStr[i] + myStr[i + 1];
  //     console.log(numStr, "num str");
  //     arr[j] = parseInt(numStr, 16);
  //     j += 1;
  //   }
  //   return arr;
  // }

  // const dnaString = dnaArrayToString(dna);
  // console.log(dnaString, "dna string");

  const value = { dna: dnaCtx.dna, address: dnaCtx.scannedAddress };

  //let valami = JSON.stringify(value);

  //const array = stringToDnaArray(dnaString);
  //console.log(array, "array");

  const goBackToCrest = () => {
    navigate("/motivum");
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#5a513f";

    document.getElementsByClassName("header")[0].style.display = "none";
    document.getElementById("RFS-StepperContainer").style.display = "none";
  }, []);

  //console.log(value, 'valueeee')

  return (
    <div className="qr-screen">
      <div className="qr-screen-title">
        <h2>a címered qr kódja</h2>
        <img src={exit} onClick={goBackToCrest}></img>
      </div>
      <p className="qr-screen-text">{t("qrText")}</p>
      <div className="qr-holder">
        <QRCode
          value={JSON.stringify(value)}
          bgColor="white"
          fgColor="black"
          size={"100%"}
        />
      </div>
    </div>
  );
};

export default QRViewer;
