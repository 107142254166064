import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import "./QRScanner.css";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/images/x-circle.png";
import { DNAContext } from "../context/DNAContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import logo from "../assets/images/logo.svg";
import config from "../config.json";

const QrScanner = ({ setWalletAddress }) => {
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const sourceCtx = useContext(DNAContext);
  const [errorText, setErrorText] = useState();
  const [canMakeCrest, setCanMakeCrest] = useState(false);

  const canMakeCrestUrl = process.env.REACT_APP_CAN_MAKE_CREST_URL;
  const { t } = useTranslation();

  const handleScan = async (scanData) => {
    if (scanData) {
      setProcessing(true);
      try {
        const scannedObj = JSON.parse(scanData);
        //console.log(scannedObj, "scanned obj");
        if (process.env.REACT_APP_KIOSK === "true") {
          console.log("obj van");

          const address = scannedObj.address;
          const dna = scannedObj.dna;

          console.log(address, "address");
          console.log(dna, "dna");

          setWalletAddress(address);
          sourceCtx.setScannedAddress(address);
          sourceCtx.setDNA(dna);
          navigate("/crest");
        }
      } catch (err) {
        console.log("link van");

        const regexForUrl = /(ethereum:|.*\?.*address=)?(0x[a-fA-F0-9]{40})$/;
        if (scanData.match(regexForUrl) === null) {
          setErrorText(
            "Hibás wallet address, szkenneld be a címer készítő appban generált QR kódot!"
          );
          setTimeout(() => {
            navigate(`/home`);
          }, 3000);
          return;
        }
        const checkedUrl = scanData.match(regexForUrl)[0];
        const regexForAddress = /0x[a-fA-F0-9]{40}/;
        const extractedAddress = checkedUrl.match(regexForAddress)[0];
        //console.log(extractedAddress, "eextracted address");

        const address = extractedAddress.toLowerCase();
        //console.log(address, 'addresss')
        setWalletAddress(address);
        sourceCtx.setScannedAddress(address);
        sourceCtx.setDNA(new Uint8Array(66));

        let error = null;

        const checkIfCanMInt = await axios
          .post(canMakeCrestUrl, { walletAddress: address })
          .then((response) => {
            console.log(response, "resp");
            let respStatus = response.status;
            if (respStatus !== 200) {
              setErrorText(response.data.message, "resp");
              error = true;
            }
          })
          .catch((e) => {
            console.log(e, " error");

            if (e.response.data.message.includes("Already minted token id:")) {
              error = 1;
            } else {
              error = 2;
            }
            setErrorText(e.response.data.message);
          });

        if (error === 1) {
          navigate(`/crest`);
        } else if (error === 2) {
          setTimeout(() => {
            navigate(`/home`);
          }, 3000);
        } else {
          navigate(`/pajzs?address=${address}`);
        }
      }
    }
  };

  // useEffect(() => {

  // }, [sourceCtx.scannedAddress])

  const handleNavigate = () => {
    navigate("/home");
  };

  const onError = (err) => {
    console.log("scanner error");
    console.error(err);
  };

  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "none";
    const stepper = (document.getElementById(
      "RFS-StepperContainer"
    ).style.display = "none");
  }, []);

  return (
    <>
      {!errorText ? (
        <div className="scanner-holder">
          <button className="qr-close-btn" onClick={handleNavigate}>
            <img src={closeBtn} />
          </button>
          {!showDialog && !processing && (
            <QrReader
              delay={500}
              onScan={handleScan}
              constraints={{
                facingMode: "environment",
              }}
              className="qr-reader"
              onError={onError}
            />
          )}
        </div>
      ) : (
        <div
          className="header"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="logo-div">
            <img
              src={logo}
              className="logo"
              style={{ maxWidth: "200px" }}
            ></img>
          </div>

          <h2
            className="page-title-home"
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.titleFont,
              margin: "40px 0",
            }}
          >
            {t("title")}
          </h2>
          <div className="errorText home-texts" style={{ fontSize: "20px" }}>
            {errorText === "You need:1 more tokens" ? t("error1token") : ""}
            {errorText === "You need:2 more tokens" ? t("error2token") : ""}
            {errorText === "You need:3 more tokens" ? t("error3token") : ""}
            {errorText.includes("Already minted token id:")
              ? "Már megszerezted a családi címeredet. Címer módosítása és bővítése hamarosan..."
              : errorText}
          </div>
        </div>
      )}
    </>
  );
};

export default QrScanner;
