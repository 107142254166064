import React, { useContext, useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Thumbs,
  Controller,
  Scrollbar,
  A11y,
} from "swiper";

import { DNAContext } from "../context/DNAContext";

import { buildSvg } from "../contract/contractMethods/svgMethods";
import classDefs from "../classes.json";
import Header from "../components/Header";
import Footer from "../components/Footer";

import chosen from "../assets/images/chosen.png";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import StepperContainer from "../components/StepperContainer";
import { StepperContext } from "../context/StepperContext";
import { Stepper } from "react-form-stepper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Thumbs, Controller, Scrollbar, A11y]);

const Pajzsformak = () => {
  const [selectedShape, setselectedShape] = useState(null);
  const [shapes, setShapes] = useState(null);
  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.outerWidth
  );

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [swiper, setSwiper] = useState(null);

  const dnaCtx = useContext(DNAContext);

  let dna = dnaCtx.dna;
  const { setCurrentStep } = useContext(StepperContext);

  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "flex";
    document.getElementById("RFS-StepperContainer").style.display = "flex";
    dnaCtx.setStepNr(1);

    //dnaCtx.setDNA(new Uint8Array(66));

    // const chosenSvg = localStorage.getItem("pajzs-svg");
    // const chosenClass = localStorage.getItem("pajzs-class");
    // const chosenSubclass = localStorage.getItem("pajzs-subclass");
    // const chosenColor = localStorage.getItem("pajzs-color");
    // //console.log(chosenSvg, 'pajzs-svg')
    // if (chosenSvg && chosenClass && chosenSubclass && chosenColor) {
    //   setselectedShape({
    //     classId: chosenClass,
    //     subclassId: chosenSubclass,
    //     color: chosenColor,
    //     shape: chosenSvg,
    //   });
    // }
    const filteredArray = Object.values(classDefs).filter(
      (v) => v.className === "pajzsformak"
    );
    setShapes(filteredArray);
  }, []);

  const goToFormak = () => {
    if (selectedShape) {
      console.log(dnaCtx, " context");
      navigate("/formak");
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (selectedShape) {
        console.log(selectedShape.shape, "shapeeeeeeeeeee");
        console.log(selectedShape.shape, "shapeeeeeeeeeee");

        dna[0] = selectedShape.classId;
        dna[1] = selectedShape.subclassId;
        dna[2] = 0;
        //console.log(dna + "before set");
        dnaCtx.setDNA(dna);
        const svg = await buildSvg(dna);
        dnaCtx.setGeneratedSvg(svg);

        // localStorage.setItem("pajzs-svg", selectedShape.shape);
        // localStorage.setItem("pajzs-class", selectedShape.classId);
        // localStorage.setItem("pajzs-subclass", selectedShape.subclassId);
        // localStorage.setItem("pajzs-color", selectedShape.color);
      }
    };
    fetch();
  }, [selectedShape]);

  useEffect(() => {
    if (selectedShape) {
      if (swiper) {
        const timer = setTimeout(() => {
          swiper.slideTo(selectedShape.subclassId - 1);
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [swiper]);

  return (
    <div className="App">
      <div>
      <h2 className="page-title-pajzsok">{t("title")}</h2>

      <p className="screens-text">{t("shapeText")}</p>
      <div className="pajzsok">
        {currentWindowWidth <= 992 && (
          <Swiper
            id="thumbs"
            spaceBetween={window.outerWidth >= 425 ? -80 : -40}
            slidesPerView={1}
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
          >
            {shapes &&
              shapes.map((shape, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      // className="pajzs-svg"
                      dangerouslySetInnerHTML={{
                        __html: shape.svg,
                      }}
                      key={shape.subclassName}
                      style={{
                        width: "305px",
                        height: "305px",
                        margin: "0 auto",
                        border:
                          selectedShape && shape.svg === selectedShape.shape
                            ? "2px solid #BA5B2D"
                            : "1px solid #00000033",
                      }}
                      onClick={() =>
                        setselectedShape({
                          classId: shape.classId,
                          subclassId: shape.subclassId,
                          color: 0,
                          shape: shape.svg,
                        })
                      }
                    ></div>

                    {selectedShape && shape.svg === selectedShape.shape && (
                      <img src={chosen} className="chosen-tag"></img>
                    )}
                    <div className="slide-nr">
                      {index + 1}/{shapes.length}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}

        {currentWindowWidth > 992 && (
          <>
            {shapes &&
              shapes.map((shape, index) => {
                return (
                  <div className="element" key={index}>
                    <div
                      // className="pajzs-svg"
                      dangerouslySetInnerHTML={{
                        __html: shape.svg,
                      }}
                      key={shape.subclassName}
                      style={{
                        // width: "305px",
                        // height: "305px",
                        // margin: "0 auto",
                        border:
                          selectedShape && shape.svg === selectedShape.shape
                            ? "2px solid #BA5B2D"
                            : "1px solid #00000033",
                      }}
                      className="pajzs"
                      onClick={() =>
                        setselectedShape({
                          classId: shape.classId,
                          subclassId: shape.subclassId,
                          color: 0,
                          shape: shape.svg,
                        })
                      }
                    ></div>

                    {selectedShape && shape.svg === selectedShape.shape && (
                      <img src={chosen} className="chosen-tag"></img>
                    )}
                    <div className="slide-nr">
                      {index + 1}/{shapes.length}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
      </div>
      <div className="holder">
        <div className="langpage-footer" style={{ bottom: "0" }}>
          <Footer />
        </div>

        <div className="sticky-button-holder">
          <div className="buttons-holder">
            <button
              className="select-button"
              onClick={() => {
                goToFormak();
                setCurrentStep(1);
              }}
              disabled={!selectedShape}
            >
           {t("choseButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pajzsformak;
