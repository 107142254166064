import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import roIcon from "../assets/images/RO.png";
import huIcon from "../assets/images/HU.png";
import usIcon from "../assets/images/US.png";
import { DNAContext } from "../context/DNAContext";
import i18next from "i18next";
import Footer from "../components/Footer";

const LanguagePage = () => {
  const navigate = useNavigate();

  const langCtx = useContext(DNAContext);
  const defaultLanguage = langCtx.language;
  //console.log(defaultLanguage, "def lang");

  const setHuLanguage = () => {
    langCtx.setLanguage("hu");
    i18next.changeLanguage("hu");
    navigate("/home");
  };

  const setRoLanguage = () => {
    langCtx.setLanguage("ro");
    i18next.changeLanguage("ro");
    navigate("/home");
  };

  const setEngLanguage = () => {
    langCtx.setLanguage("en");
    i18next.changeLanguage("en");
    navigate("/home");
  };

  useEffect(() => {
    document.getElementsByClassName("header")[0].style.display = "none";
    const stepper = document.getElementById("RFS-StepperContainer").style.display = "none";
  }, []);

  
  console.log(langCtx.scannedAddress, 'address');
  console.log(langCtx.dna, 'dna')

  return (
    <div className="language-page">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <h2
        className="page-title"
        style={{
          // color: config.MUZEUM.titleColor,
          // WebkitTextStroke: `1px ${config.CAMELCODING.titleColor}`,
        }}
      >
        Alege limba/
        <br /> válaszd ki a nyelvet/
        <br /> Select your language
      </h2>
      <div className="language-buttons-holder">
        <button className="language-button" onClick={setRoLanguage}>
          <img src={roIcon}></img>Română
        </button>
        <button className="language-button" onClick={setHuLanguage}>
          <img src={huIcon}></img>Magyar
        </button>
        <button className="language-button" onClick={setEngLanguage}>
          <img src={usIcon}></img>English
        </button>
      </div>
      </div>

      <div className="langpage-footer" style={{bottom: '24px'}}>
        <Footer />
      </div>
    </div>
  );
};

export default LanguagePage;
