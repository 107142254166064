import svgTags from "../../svgTags.json";
import classDefs from "../../classes.json";
import styles from '../../styles.json';

export async function buildSvg(dna) {
  let finalSvg = svgTags.openingTag;
  for (let i = 0; i < 66; i += 3) {
    let classId = dna[i];
    let subclassId = dna[i + 1];
    let styleId = dna[i + 2];
    if (classId !== 0 && subclassId !== 0) {
      const key = classId.toString() + '/' + subclassId.toString();
      let svgPart = classDefs[key].svg;
      if (styleId !== 0) {
        let style = styles[styleId].type +'="' + styles[styleId].code + '"';
       // console.log(style + " style plsss")
        finalSvg = finalSvg.concat("<g ", style, ">", svgPart, "</g>");
      } else {
        finalSvg = finalSvg.concat(svgPart);
      }
    }
  }
  return finalSvg.concat(svgTags.closingTag);
}

export function buildDna(classDefs, styles, dna, fromIndex) {
  const svgsLength = classDefs.length;
  if (fromIndex + svgsLength >= dna.length) throw new Error("invalid length");
  const newDna = [...dna];
  let classId;
  let subclassId;
  for (let i = 0; i < svgsLength; i++) {
    newDna[fromIndex] = classDefs[i].classId;
    classId = classDefs[i].classId;
    newDna[fromIndex + 1] = classDefs[i].subclassId;
    subclassId = classDefs[i].subclassId;
    console.log(styles + " BUILDDNA STYLE")
    console.log(styles[i], 'style')
    newDna[fromIndex + 2] = styles[i].id;
    fromIndex += 3;
  }
  return { dna: newDna, nrOfDivisions: svgsLength, classId: classId, subclassId: subclassId };
}

export function setStyle(classId, subclassId, styleId, dna) {
  let i = 0;
  let newDna = [...dna];
  while (i < dna.length) {
    if (dna[i] === classId && dna[i + 1] === subclassId) {
      newDna[i + 2] = styleId;
      //console.log(styleId, "style id in function");
      return newDna;
    }
    i += 3;
  }
  return newDna;
}